import React from 'react'
import Layout from '../components/layout.js'
import NotFound from '../components/not-found.js'

const pageError = () => {
  return (
    <Layout>
      <NotFound/>
    </Layout>
  )
}

export default pageError
