import React from 'react'

const NotFound = () => {
  return (
    <div className="page-not-found">
      <h3 className="h-l">Page not found</h3>
    </div>
  )
}

export default NotFound
